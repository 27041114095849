/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --product-gallery-grid-order: 0;
    --product-actions-grid-order: 1;
    --product-attribute-icons-grid-order: 2;
    --product-gallery-max-width: 1fr;
    --product-actions-col-3-max-width: 45.5rem;
    --product-actions-col-2-max-width: 100%;
    --product-attribute-icons-max-width: 38rem;

    @include mobile {
        --product-actions-max-width: 100%;
    }
}


.ProductPage {
    @include desktop {
        padding-top: 1.2rem;
    }

    @include tablet {
        padding-top: 0;
        margin-top: 0;
    }

    @include mobile {
        --product-discount-label-group-right: 2rem;

        padding-bottom: 75px;
    }

    &-Wrapper {
        display: grid;
        grid-template-rows: min-content min-content;
        padding-top: 2.4rem;
        grid-template-columns: 1fr minmax(0, 1fr);

        @include mobile {
            grid-column-gap: 3rem;
            grid-template-columns: 1fr;
        }

        @include desktop {
            grid-column-gap: 4.8rem;
            padding-bottom: 7.2rem;
        }
        
        .ProductActions {
            max-width: var(--product-actions-col-2-max-width);
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    .ProductGallery {
        order: var(--product-gallery-grid-order);
        
        @include mobile {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }

    .ProductActions {
        order: var(--product-actions-grid-order);
    }

    .ProductAttributeIcons {
        order: var(--product-attribute-icons-grid-order);
    }

    &-ProductCompareButtonWrapper {
        position: absolute;
        top: 0;
        right: 1.4rem;
    }
}