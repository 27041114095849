/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    
    --section-nav-item-background-color: #F9F9F9;
    --section-nav-item-color: #0a0a0a;
    --section-nav-item-font-size: 1.6rem;
    --section-nav-item-background-color-active: var(--primary-base-color);
    --section-nav-item-color-active: #ffffff;

    @include desktop {
        --section-nav-wrapper-spacing: 2rem;
    }

    @include mobile {
        --section-nav-wrapper-spacing: 1.5rem;
    }
}

.ProductSectionNavigation {
    &-Wrapper {
        padding: var(--content-wrapper-padding-inline);
    }

    &-Nav {
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;

        .SaasScrollToLink {
            margin-right: 1.5rem;

            @include mobile {
                margin-right: 0;
            }
        }

        @include mobile {
            flex-direction: column;
            padding: 0 1.5rem;
        }
    }

    &-NavItem {
        > a {
            padding: 1rem 3rem;
            color: var(--section-nav-item-color);
            background-color: var(--section-nav-item-background-color);
            font-size: 1.6rem;
            display: block;

            @include mobile {
                font-size: 1.4rem;
                margin-left: 0;
                margin-bottom: 1rem;
                text-align: center;
                margin-right: 0;
            }

            &:hover,
            &:focus {
                background-color: var(--section-nav-item-background-color-active);
                color: var(--section-nav-item-color-active);
                text-decoration: none;
            }
        }
    }

    &-ItemInner {
        display: flex;

        .ProductAttributes {

            &-ExpandableContentContent {
                columns: 1;
            }

            &-AttributeLabel {
                padding: 0;

                &:after {
                    content: ":";
                }
            }

            &-ValueLabel {
                margin-bottom: 1.2rem;
                padding: 0;
            }

            @include mobile {
                margin-top: 2rem;
            }
        }

        .ProductInformation,
        .ProductAttributes {
            &-Wrapper {
                padding: 0;
            }

            @include mobile {
                width: 100%;
            }

            &-ExpandableContentContent {
                display: block;

                &_isContentExpanded {
                    @include mobile {
                        padding: 0;
                    }
                }

                * {
                    font-size: 1.6rem;

                    @include mobile {
                        font-size: 1.4rem;
                    }
                }
            }

            &-ExpandableContentButton {
                display: flex;

                @include desktop {
                    display: inline-block;
                }
                
                .ExpandableContent-Heading {
                    font-size: 2rem;

                    @include mobile {
                        font-size: 1.6rem;
                    }
                }

                @include mobile {
                    padding: 1.5rem 0 1rem 0;
                }
            }
        }

        &_info_button {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            grid-column-gap: 4.8rem;

            @include mobile {
                grid-template-columns: 1fr;
                padding: 0 1.5rem;
            }
        }

        .ProductLinks {
            &-Wrapper {
                padding: 0;
            }

            &-Title {
                padding: 1.2rem 0 0;
                 @include mobile {
                     font-size: 1.6rem;
                     text-align: center;
                 }
            }

            &-List {
                grid-template-columns: repeat(4, 1fr);

                @include mobile {
                    grid-template-columns: 1fr;
                }
            }
        }

        /*
         * TODO: Remove this code from plugin when product reviews is fixed
        **/
        .ProductReviews {
            &-Wrapper {
                padding: 0;

                .ProductReviewList {
                    grid-template-columns: 1fr;
                }
            }
        }

        &Reviews {
            display: flex;
            margin-top: 6rem;
            
            > * {
                width: 100%;
            }

            @include mobile {
                flex-direction: column;
            }
        }
    }
}