/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/image';
@import '../../../../style/abstract/parts';

:root {
    --show-all-products-by-attribute-widget-background-color: #efefef;
    --show-all-products-by-attribute-widget-link-color: var(--primary-base-color);
}

.ShowAllProductsByAttributeWidget {
    background-color: var(--show-all-products-by-attribute-widget-background-color);
    margin-top: 20px;
    padding: 12px 15px;
    
    &-Link {
        color: var(--show-all-products-by-attribute-widget-link-color);
        margin-bottom: 10px;

        a {
            font-weight: bold;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}