/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --product-list-widget-page-grid-template-columns: repeat(4, 1fr);
    --product-list-widget-h2-font-size: 4rem;
    --product-list-widget-h2-font-style: italic;
    --product-list-widget-h2-font-weight: bold;

    @include mobile {
        --product-list-widget-h2-font-size: 2rem;
    }

    @include mobile-s {
        --product-list-widget-page-grid-template-columns: 1fr 1fr;
    }

    @include mobile-l {
        --product-list-widget-page-grid-template-columns: 1fr 1fr;
    }

    @include tablet {
        --product-list-widget-page-grid-template-columns: repeat(3, 1fr);
        --product-list-widget-h2-font-size: 3rem;
    }
}

@at-root .pageHasSidebar {
    @include desktop {
        --product-list-widget-page-grid-template-columns: repeat(3, 1fr);
    }
}

.ProductListWidget {
    max-width: var(--content-wrapper-width);
    margin: 0 auto;
    padding: 0 2rem;

    h2 {
        font: {
            size: var(--product-list-widget-h2-font-size);
            style: var(--product-list-widget-h2-font-style);
            weight: var(--product-list-widget-h2-font-weight);
        }
        text: {
            transform: var(--product-list-widget-h2-text-transform)
        };

        @include desktop {
            padding: 0 1.5rem;
        }
      }

    @include desktop {
        grid-column: 2;
        padding: 0 0 2.4rem 0;
        min-height: auto;
    }

    &-Page {
        display: grid;
        grid-template-columns: var(--product-list-widget-page-grid-template-columns);
        grid-auto-rows: max-content;
        grid-column-gap: 4rem;

        @include tablet {
            gap: 3rem;
        }

        @include mobile {
            gap: 2rem;
            border: 0;
        }

        @include desktop {
            padding: 0 1.5rem;

            .CmsPage-Content & {
                padding: 0;
            }
        }
    }
}
