/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --product-usp-icon-color: var(--primary-base-color);
    --product-usp-item-color: #000;
    --product-usp-font-size: 1.6rem;

    @include mobile {
        --product-usp-item-color: #000;
        --product-usp-font-size: 1.4rem;
    }
}

.ProductUsp {

    &-List {
        display: flex;
        flex-direction: column;
    }

    &-Item {
        @include reset-list-item;

        color: var(--product-usp-item-color);
        display: flex;
        align-items: center;
        margin-bottom: 1.3rem;

        &:not(:last-of-type) {
            margin-right: 0;
        }

        &_isSlide {
            padding: 0 1rem;
            justify-content: center;
        }
    }

    &-Icon {
        color: var(--product-usp-icon-color);
        font-size: var(--site-usp-font-size);
        margin-right: 1.2rem;
        text-align: center;
        width: 3rem;
    }

    &-Content {
        font-style: normal;
        font-size: var(--product-usp-font-size);
    }
}