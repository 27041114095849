/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --attribute-label-item-label-font-size: 1.4rem;
    --attribute-label-item-label-font-weight: 700;
    --attribute-label-item-label-color: var(--primary-base-color); 
    --attribute-label-item-value-font-size: var(--attribute-label-item-label-font-size);
    --attribute-label-item-value-font-weight: 400;
    --attribute-label-item-value-color: #0a0a0a;
    --product-title-hightlight-color: var(--attribute-label-item-label-color); 
}

.SaasProductTitleBlock {
    display: grid;
    grid-template-rows: 2.2rem min-content 2.2rem;
    grid-template-areas: 
        "super"
        "title"
        "sub"
    ;
    margin-bottom: 1rem;

    h1,
    h2 {
        grid-area: title;
        line-height: 3rem;
        font-weight: 700;
        margin: 0;

        @include mobile {
            font-size: 1.8rem;
        }
    }

    h1 {
        text-transform: none;
        font-size: var(--h2-font-size);
    }

    &-AttributeSuper {
        grid-area: super;
        font-size: 1.8rem;

        .ProductCard & {
            font-size: 1.4rem;
        }
    }

    &-AttributeSub {
        grid-area: sub;
    }

    &-AttributeSub,
    &-AttributeSuper {
        display: flex;
        align-items: center;

        &Label {
            font-size: var(--attribute-label-item-label-font-size);
            font-weight: var(--attribute-label-item-label-font-weight);
            color: var(--attribute-label-item-label-color);
            margin-right: 0.7rem;
        }

        &Value {
            font-size: var(--attribute-label-item-value-font-size);
            font-weight: var(--attribute-label-item-value-font-weight);
            color: var(--attribute-label-item-value-color);
        }

        .ProductCard & {
            font-size: 1.4rem;
        }
    }

    .ProductActions-TitleHighlight {
        color: var(--product-title-hightlight-color);
    }
}